/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DataContextProvider } from '../data-context';
import MenuBar from './menuBar';
import styled, { ThemeProvider } from 'styled-components';
import { detect } from 'detect-browser';
import GlobalStyle from '../styles/globalStyles';
import theme from '../styles/theme';

const browser = detect();

const ChildrenWrapper = styled.div`
    height: 100%;
    overflow: hidden;
    flex: 1;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100vw;
    align-items: stretch;
    @media screen and (min-width: 720px) {
        flex-direction: row;     
    }
`;

const Layout = ({ children }) => {
    return (
        <DataContextProvider>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <MainWrapper>
                    <MenuBar isEdge={browser.name === 'edge'} />
                    <ChildrenWrapper>
                        <main>{children}</main>
                    </ChildrenWrapper>
                </MainWrapper>
            </ThemeProvider>
        </DataContextProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
