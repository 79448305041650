/* eslint-disable max-lines-per-function */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

/**
 *
 * @param {string} description Description that appears on search engines.
 * @param {string} lang Language of the page
 * @param {*} meta Meta of the page.
 * @param {string} title Title that appears in the browser window.
 * * @param {string} canonical canonical url.
 * @returns {ReactChild} <Seo></Seo>
 */
function SEO({ description, lang, meta, title, canonical }) {
    const metaDescription = description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | McClennan Masson`}
            meta={[
                {
                    property: `og:url`,
                    content: canonical
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}>
                { canonical && <link rel='canonical' href={canonical} /> }
                </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    canonical: PropTypes.string
};

export default SEO;
