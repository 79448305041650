import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

const DataContext = createContext({
    data: {},
    updateDataField: () => {},
});

/**
 * @param {*} children Children of the React Node
 * @returns {Node} <DataContext></DataContext>
 */
export function DataContextProvider({ children }) {
    const [data, setData] = useState({
        selected: null,
    });

    const updateDataField = useCallback(
        (label, value) => {
            setData(state => ({
                ...state,
                [label]: value,
            }));
        },
        [setData]
    );

    const contextValue = useMemo(
        () => ({
            data,
            updateDataField,
        }),
        [data, updateDataField]
    );
    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
}

DataContextProvider.propTypes = {
    children: PropTypes.any,
};

export default DataContext;
