/* eslint-disable max-lines */

const theme = {
    main: '#ED1E24',
    grey: '#828282',
    white: '#ffffff',
    partner: '#F2F4FC',
    lightGrey: '#8c8c8c',
    black: '#212121',
    fonts: {
        main: 'Söhne Breit',
    },
    paddings: {
        mobile: '30px',
        575: '40px',
        720: '40px',
        1024: '64px',
        1440: '100px',
    },
    responsiveElements: {
        p: {
            general: {
                mobile: {
                    fontSize: 12,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 16,
                    lineHeight: 32,
                },
                720: {
                    fontSize: 12,
                    lineHeight: 24,
                },
                1024: {
                    fontSize: 16,
                    lineHeight: 32,
                },
                1440: {
                    fontSize: 20,
                    lineHeight: 40,
                }
            },
            productDescription: {
                mobile: {
                    fontSize: 12,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 16,
                    lineHeight: 32,
                },
                720: {
                    fontSize: 14,
                    lineHeight: 28,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 40,
                },
                1440: {
                    fontSize: 24,
                    lineHeight: 48,
                }
            },
            bigger: {
                mobile: {
                    fontSize: 12,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 16,
                    lineHeight: 32,
                },
                720: {
                    fontSize: 12,
                    lineHeight: 24,
                },
                1024: {
                    fontSize: 16,
                    lineHeight: 32,
                },
                1440: {
                    fontSize: 20,
                    lineHeight: 40,
                }
            },
            bubblesTopBottom: {
                mobile: {
                    fontSize: 13,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 18,
                    lineHeight: 30,
                },
                720: {
                    fontSize: 12,
                    lineHeight: 24,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 32,
                },
                1440: {
                    fontSize: 24,
                    lineHeight: 48,
                }
            },
        },
        a: {
            linkWithSvg: {
                mobile: {
                    fontSize: 14,
                    lineHeight: 26,
                },
                575: {
                    fontSize: 18,
                    lineHeight: 52
                },
                720: {
                    fontSize: 12,
                    lineHeight: 60,
                },
                1024: {
                    fontSize: 16,
                    lineHeight: 60,
                },
                1440: {
                    fontSize: 20,
                    lineHeight: 60,
                }
            }
        },
        h1: {
            general: {
                mobile: {
                    fontSize: 20,
                    lineHeight: 28,
                },
                575: {
                    fontSize: 28,
                    lineHeight: 36,
                },
                720: {
                    fontSize: 20,
                    lineHeight: 30,
                },
                1024: {
                    fontSize: 32,
                    lineHeight: 44,
                },
                1440: {
                    fontSize: 40,
                    lineHeight: 52,
                }
            },
            section: {
                mobile: {
                    fontSize: 20,
                    lineHeight: 30,
                },
                575: {
                    fontSize: 28,
                    lineHeight: 36,
                },
                720: {
                    fontSize: 20,
                    lineHeight: 52,
                },
                1024: {
                    fontSize: 24,
                    lineHeight: 52,
                },
                1440: {
                    fontSize: 32,
                    lineHeight: 52,
                }
            },
        },
        h2: {
            general: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 24,
                    lineHeight: 32,
                },
                720: {
                    fontSize: 20,
                    lineHeight: 60,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 60,
                },
                1440: {
                    fontSize: 28,
                    lineHeight: 60,
                }
            },
            section: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 24,
                    lineHeight: 30,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 45,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 45,
                },
                1440: {
                    fontSize: 28,
                    lineHeight: 45,
                }
            },
            home: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 24,
                    lineHeight: 32,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 60,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 60,
                },
                1440: {
                    fontSize: 24,
                    lineHeight: 60,
                }
            },
            media: {
                mobile: {
                    fontSize: 20,
                    lineHeight: 32,
                },
                575: {
                    fontSize: 28,
                    lineHeight: 42,
                },
                720: {
                    fontSize: 20,
                    lineHeight: 28,
                },
                1024: {
                    fontSize: 24,
                    lineHeight: 36,
                },
                1440: {
                    fontSize: 32,
                    lineHeight: 48,
                }
            },
            carousel: {
                mobile: {
                    fontSize: 20,
                    lineHeight: 32,
                },
                575: {
                    fontSize: 28,
                    lineHeight: 36,
                },
                720: {
                    fontSize: 20,
                    lineHeight: 60,
                },
                1024: {
                    fontSize: 24,
                    lineHeight: 60,
                },
                1440: {
                    fontSize: 32,
                    lineHeight: 60,
                }
            },
            bubbles: {
                mobile: {
                    fontSize: 40,
                    lineHeight: 60,
                },
                575: {
                    fontSize: 48,
                    lineHeight: 60,
                },
                720: {
                    fontSize: 32,
                    lineHeight: 60,
                },
                1024: {
                    fontSize: 50,
                    lineHeight: 60,
                },
                1440: {
                    fontSize: 65,
                    lineHeight: 60,
                }
            },
            bubblesTopBottom: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 24,
                    lineHeight: 30,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 32,
                },
                1440: {
                    fontSize: 32,
                    lineHeight: 48,
                }
            },
            bigger: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 20,
                    lineHeight: 28,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 28,
                },
                1440: {
                    fontSize: 24,
                    lineHeight: 32,
                }
            }
        },
        h3: {
            bubbles: {
                mobile: {
                    fontSize: 32,
                    lineHeight: 60,
                },
                575: {
                    fontSize: 40,
                    lineHeight: 60,
                },
                720: {
                    fontSize: 28,
                    lineHeight: 60,
                },
                1024: {
                    fontSize: 40,
                    lineHeight: 60,
                },
                1440: {
                    fontSize: 60,
                    lineHeight: 60,
                }
            },
            grid: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 24,
                    lineHeight: 31,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 20,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 25,
                },
                1440: {
                    fontSize: 28,
                    lineHeight: 36,
                }
            },
            media: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 24,
                    lineHeight: 36,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 32,
                },
                1440: {
                    fontSize: 32,
                    lineHeight: 48,
                }
            }
        },
        h4: {
            bubbles: {
                mobile: {
                    fontSize: 36,
                    lineHeight: 60,
                },
                575: {
                    fontSize: 52,
                    lineHeight: 60,
                },
                720: {
                    fontSize: 32,
                    lineHeight: 60,
                },
                1024: {
                    fontSize: 44,
                    lineHeight: 60,
                },
                1440: {
                    fontSize: 64,
                    lineHeight: 60,
                }
            },
            bigger: { // !!
                mobile: {
                    fontSize: 13,
                    lineHeight: 23,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 32,
                },
                1024: {
                    fontSize: 20,
                    lineHeight: 32,
                },
                1440: {
                    fontSize: 25,
                    lineHeight: 50,
                }
            },
            media: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 24,
                    lineHeight: 32,
                },
                720: {
                    fontSize: 16,
                    lineHeight: 38,
                },
                1024: {
                    fontSize: 16,
                    lineHeight: 38,
                },
                1440: {
                    fontSize: 28,
                    lineHeight: 38,
                }
            }
        },
        h5: {
            general: {
                mobile: {
                    fontSize: 16,
                    lineHeight: 28,
                },
                575: {
                    fontSize: 20,
                    lineHeight: 36,
                },
                720: {
                    fontSize: 18,
                    lineHeight: 36,
                },
                1024: {
                    fontSize: 24,
                    lineHeight: 36,
                },
                1440: {
                    fontSize: 28,
                    lineHeight: 60,
                }
            }
        },
        h6: {
            general: {
                mobile: {
                    fontSize: 12,
                    lineHeight: 24,
                },
                575: {
                    fontSize: 16,
                    lineHeight: 32,
                },
                720: {
                    fontSize: 12,
                    lineHeight: 54,
                },
                1024: {
                    fontSize: 16,
                    lineHeight: 54,
                },
                1440: {
                    fontSize: 20,
                    lineHeight: 54,
                }
            }
        },
        tags: {
            general: {
                mobile: {
                    fontSize: 13,
                    lineHeight: 22,
                },
                575: {
                    fontSize: 14,
                    lineHeight: 28,
                },
                720: {
                    fontSize: 10,
                    lineHeight: 20,
                },
                1024: {
                    fontSize: 14,
                    lineHeight: 28,
                },
                1440: {
                    fontSize: 18,
                    lineHeight: 36,
                }
            }
        },
        menu: {
            mobile: {
                fontSize: 14,
                lineHeight: 48,
            },
            575: {
                fontSize: 16,
                lineHeight: 52,
            },
            720: {
                fontSize: 10,
                lineHeight: 13,
            },
            1024: {
                fontSize: 16,
                lineHeight: 20,
            },
            1440: {
                fontSize: 20,
                lineHeight: 25,
            }
        },
        imageAndText: {
            home: {
                mobile: {
                    height: 300,
                },
                575: {
                    height: 380,
                },
                720: {
                    height: 325,
                },
                1024: {
                    height: 400,
                },
                1440: {
                    height: 500,
                }
            }
        },
    }
};

export default theme;
