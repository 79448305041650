import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import logoSvg from '../../assets/logo.svg';
import logoMobile from '../../assets/logo-mobile.svg';
import menu from '../../assets/hamburger-menu.svg';

const MobileMenuSection = styled.div`
    background-color: ${ props => props.theme.main};
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    min-height: 95px;
    outline: none;
    user-select: none;
    padding: 27.5px 31px 27.5px 30px;
    position: fixed;
    top: 0;
    visibility: ${props => !props.hide ? 'hidden' : 'visible'};
    transition: ${props => !props.hide ? 'all 300ms ease-out' : 'all 300ms ease-in'};
    transform:  ${props => !props.hide ? 'translate(0, -100%)' : 'none'};
    width: 100%;
    z-index: 13;
    
    @media screen and (min-width: 720px) {
        display: none;
    }
`;

const TopLayer = styled.div`
    display: flex;
    flex-direction: row;
     z-index: 11;
`;

const BottomLayer = styled.div`
    background-color: ${ props => props.theme.main};
    left: 0;
    ${ props => !props.open && 'overflow: hidden'};
    padding: 0 34px;
    position: absolute;
    top: 94px;
    visibility: ${props => !props.open ? 'hidden' : 'visible'};
    transition: ${props => !props.open ? 'all 200ms ease-out' : 'all 200ms ease-in'};
    transform:  ${props => !props.open ? 'translate(0, -100%)' : 'none'};
    width: 100%;
    z-index: 10;
    
    @media screen and (min-width: 575px) {
         padding: 0 39px;
    }
`;

const MenuImage = styled.img``;

const CloseIcon = styled.span`
    color: #fff;
    font-size: 34px;
    height: 40px;
    line-height: 36px;
    outline: none;
    text-align: center;
    width: 40px;
`;

const MobileMenuItems = styled.div`
    width: 50%;     
`;

const HamburgerMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
`;

const Logo = styled(Link)`
    display: flex;
    max-height: 35px;
    outline: none;
    width: 50%;
    
    @media (min-width: 1024px) {
        cursor: pointer;
    }
`;

const LogoImage = styled.img`
    margin-left: auto;
`;

const MenuItem = styled(Link)`
    color: #fff; 
    display: flex;
    flex-direction: column;
    font-family: ${props => props.theme.fonts.main};
    line-height: 50px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    max-height: 120px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    
    @media (max-width: 719px) {
        ${props => props.open ? 'visibility: visible;opacity: 1;transition: opacity 0.7s linear;' :
        ' visibility: hidden;opacity: 0;transition: visibility 0s 0s, opacity 0s linear;'};  
    }
    
`;

const MenuItems = styled.div`
    transition: .5s all;
    padding: 12.5px 0 27.5px 0;
    
    @media screen and (min-width: 575px) {
         padding: 16px 0 31px 0;
    }

    @media (min-width: 720px) {
        padding: 0;
        a:nth-child(2) {
            margin: 40px 0;
        }
    }
    
    /* @media (max-width: 1024px) and (orientation: landscape) {
        a:nth-child(2) {
            margin: 0;
        }
    } */
    @media (min-width: 1024px) {
        a:nth-child(2) {
            margin: 40px 0;
        }
    }
    @media (min-width: 1440px) {
        a:nth-child(2) {
            margin: 50px 0;
        }
    }
    @supports (-ms-ime-align:auto) {
        top: 0px;
    }
`;

const MenuSection = styled.section` 
    display: none;
    // starting from 720px -- below is mobile MobileMenuSection component

    @media (min-width: 720px) {
        background-color: ${ props => props.theme.main};
        display: block;
        min-height: 100vh;
        overflow: hidden;
        position: relative;
        transition: 0.5s all ease;
        flex: 0 0 64px;
        z-index: 1;
         
        & ${Logo} {
            margin: 0 auto;
            min-height: 70px;
            position: fixed;
            top: 30px;
            width: 64px;
        }
    
        & ${LogoImage} {
            margin: 0 auto;
            transform: none;
            width: 23px;
        }
    
        & ${MenuItems} {
            height: 64px;
            width: auto;
            position: fixed;
            top: 130px;
            @media (min-width: 720px) {
                height: calc(100% - 130px);
            }
        }   
    
        & ${MenuItem} {
            display: block;
            height: auto;
            line-height: 64px;
            margin: 0 auto;
            writing-mode: vertical-rl;
            outline: none;
            transform: rotateX(180deg) rotateY(180deg);
            text-orientation: sideways;
        }  
    }
    
    @media (min-width: 1024px) {
        flex: 0 0 90px;
        & ${Logo} {
            max-width: 90px;
            min-height: 113px;
            top: 40px;
            width: 100%;
        }
        & ${LogoImage} {
            width: 37px;
        }
        & ${MenuItems} {
            top: 212px;
            height: calc(100% - 212px);
        }
        & ${MenuItem} {   
            height: auto;
            line-height: 90px;
        }
    }
    
    @media (min-width: 1440px) {
        flex: 0 0 120px;
        & ${Logo} {
            max-width: 120px;
            min-height: 135px;
            width: 120px;
        }
        & ${LogoImage} {
            width: 45px;
        }
        & ${MenuItems} {
            top: 258px;
            height: calc(100% - 258px);
        }
        & ${MenuItem} {   
            line-height: 120px;  
        }
    }
`;

const renderMenuItems = isOpenMenu => {
    const isHomePage = typeof window !== 'undefined' && window.location.pathname === '/';
    return (
        <MenuItems>
            <MenuItem to={isHomePage ? '/#our-work-matches-up-with-the-real-world' : '/' }
                      open={isOpenMenu} tabIndex={0} aria-label='link to work pages'
                onClick={() => !isHomePage && localStorage.setItem('scrollAfterCarousel', true )}>Work</MenuItem>
            <MenuItem to='/about' open={isOpenMenu} tabIndex={0} aria-label='link to about page'>About</MenuItem>
            <MenuItem to='/contact' open={isOpenMenu} tabIndex={0} aria-label='link to contact page'>Contact</MenuItem>
        </MenuItems>
    );
};

const desktopMenu = data => (
    <MenuSection className="menu" isEdge={data.isEdge}>
        <Logo to='/'>
            <LogoImage src={logoSvg} />
        </Logo>
        {renderMenuItems()}
    </MenuSection>
);

const mobileMenu = () => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [hideOnScroll, setHideOnScroll] = useState(true);
    useEffect(() => {
        if (window.scrollY < 100) {
            setHideOnScroll(true);
        }
    });
    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y > prevPos.y;
        if (window.scrollY < 100) {
            setHideOnScroll(true);
        }
        if (isShow !== hideOnScroll) {
            currPos.y < -100 && setHideOnScroll(isShow);
            setIsOpenMenu(false);
        }
    }, [hideOnScroll]);

    return (
        <MobileMenuSection className="menu" hide={hideOnScroll}>
            <TopLayer>
                <MobileMenuItems>
                    <HamburgerMenu onClick={() => setIsOpenMenu(!isOpenMenu)}>
                        {isOpenMenu ? <CloseIcon>×</CloseIcon> : <MenuImage src={menu} />}
                    </HamburgerMenu>
                </MobileMenuItems>
                <Logo to='/' tabIndex={0} aria-label={'link to home page'}>
                    <LogoImage src={logoMobile} alt='Mcclennan logo image' />
                </Logo>
            </TopLayer>
            <BottomLayer open={isOpenMenu}>
                {renderMenuItems(isOpenMenu)}
            </BottomLayer>
        </MobileMenuSection>
    );
};

const MenuBar = data => {
    const [domLoaded, setDomLoaded] = useState(false);
    useEffect(() => {
        setTimeout(setDomLoaded(true), 500);
    }, [domLoaded]);
    return (
        <>
            {mobileMenu(data)}
            {domLoaded && desktopMenu(data)}
        </>
    );
};


MenuBar.propTypes = {
    data: PropTypes.object,
};

export default MenuBar;
