/* eslint-disable max-lines */
import { createGlobalStyle } from 'styled-components';
import Buch from '../styles/fonts/SöhneBreit-Buch.otf';
import Dreiviertelfett from '../styles/fonts/SöhneBreit-Dreiviertelfett.otf';
import BuchKursiv from '../styles/fonts/SöhneBreit-BuchKursiv.otf';
import Icons from '../styles/fonts/icomoon.woff';
import theme from './theme';

/**
 * Generates CSS in text form to inject into styled components
 * @param {String} element Element from attached theme Ex: p; h1; h2
 * @param {String} type Type variation from attached theme Ex: general; bubbles;
 * @returns {String} CSS with breakpoints
 */
const generateResponsiveCSS = (element, type) => {
    let returnObject = ``;
    const compileAttributes = (item) => {
        let attributeString = '';
        Object.keys(item).forEach(attribute => {
            attributeString = `${attributeString}${`${extractAttribute(item, attribute)}\n`}`;
        });
        return attributeString;
    };
    const extractAttribute = (item, attribute) => {
        if (item) {
            if (item[attribute]) {
                switch (attribute) {
                    case 'fontSize': {
                        return `font-size: ${item[attribute]}px;`;
                    }
                    case 'lineHeight': {
                        return `line-height: ${item[attribute]}px;`;
                    }
                    case 'height': {
                        return `height: ${item[attribute]}px;`;
                    }
                    default: {
                        return `${attribute}: ${item[attribute]};`;
                    }
                }
            }
        }
    };
    try {
        let responsiveElement = theme.responsiveElements[element];
        if (type) {
            responsiveElement = responsiveElement[type];
        }
        returnObject = compileAttributes(responsiveElement.mobile);
        Object.entries(responsiveElement).forEach((item, index) => {
            let attributeString = '';
            Object.keys(item[1]).forEach(attribute => {
                attributeString = `${attributeString}${`${extractAttribute(item[1], attribute)}\n`}`;
            });
            if (item[0] !== 'mobile') {
                returnObject =
                    `${returnObject}\n@media screen and (min-width: ${item[0]}px) {\n${compileAttributes(item[1])}}`;
            }
        }
        );
    } catch (e) {
        console.warn(`Could not generate ${element} ${type} template.`);
        return '';
    }
    return returnObject;
};
/**
 * This is where the global css is applied from.
 * @param {Object} props Props for the global style, such as theme.
 * @returns {Node} <GlobalStyle></GlobalStyle>
 */
const GlobalStyle = createGlobalStyle`

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
progress,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
progress {
    vertical-align: baseline;
}
[hidden],
template {
    display: none;
}
a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    outline-width: 0;
}
a:active,
a:hover {
    outline-width: 0;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
b,
strong {
    font-weight: inherit;
    font-weight: bolder;
}
dfn {
    font-style: italic;
}
h1 {
    color: #1a1a1a;
    margin: 0;
    font-style: normal;
    font-weight: normal;
}
mark {
    background-color: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
img {
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
figure {
    margin: 1em 40px;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
    font: inherit;
    margin: 0;
}
optgroup {
    font-weight: 700;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
[type='reset'],
[type='submit'],
button,
html [type='button'] {
    -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText;
}
fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
textarea {
    overflow: auto;
}
[type='checkbox'],
[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}
[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
html {
    font: 112.5%/1.45em georgia, serif;
    box-sizing: border-box;
}
* {
    box-sizing: inherit;
}
*:before {
    box-sizing: inherit;
}
*:after {
    box-sizing: inherit;
}
body {
    color: hsla(0, 0%, 0%, 0.8);
    font-family: ${theme.fonts.main};
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}
img {
    max-width: 100%;
    margin: 0;
    padding: 0;
}
hgroup {
    margin: 0;
    padding: 0;
}
ul {
    margin: 0;
    padding: 0;
    list-style-position: outside;
    list-style-image: none;
}
ol {
    margin: 0;
    padding: 0;
    list-style-position: outside;
    list-style-image: none;
}
dl {
    margin: 0;
    padding: 0;
}
dd {
    margin: 0;
    padding: 0;
}
figure {
    margin: 0;
    padding: 0;
}
pre {
    display: block;
    margin: 0;
    padding: 0;
    background: none;
    overflow: auto;
    word-wrap: normal;
}
table {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.45rem;
    border-collapse: collapse;
    width: 100%;
}
fieldset {
    margin: 0;
    padding: 0;
}
blockquote {
    margin: 0;
    padding: 0;
}
form {
    margin: 0;
    padding: 0;
}
noscript {
    margin: 0;
    padding: 0;
}
iframe {
    margin: 0;
    padding: 0;
}
hr {
    margin: 0;
    padding: 0;
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
}
address {
    margin: 0;
    padding: 0;
}
b {
    font-weight: bold;
}
strong {
    font-weight: bold;
}
dt {
    font-weight: bold;
}
th {
    font-weight: bold;
}
li {
    margin-bottom: calc(1.45rem / 2);
}
ol li {
    padding-left: 0;
}
ul li {
    padding-left: 0;
}
li > ol {
    margin-left: 1.45rem;
    margin-bottom: calc(1.45rem / 2);
    margin-top: calc(1.45rem / 2);
}
li > ul {
    margin-left: 1.45rem;
    margin-bottom: calc(1.45rem / 2);
    margin-top: calc(1.45rem / 2);
}
blockquote *:last-child {
    margin-bottom: 0;
}
li *:last-child {
    margin-bottom: 0;
}
p *:last-child {
    margin-bottom: 0;
}
li > p {
    margin-bottom: calc(1.45rem / 2);
}
code {
    font-size: 0.85rem;
    line-height: 1.45rem;
}
kbd {
    font-size: 0.85rem;
    line-height: 1.45rem;
}
samp {
    font-size: 0.85rem;
    line-height: 1.45rem;
}
abbr {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
}
acronym {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
}
abbr[title] {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
    text-decoration: none;
}
thead {
    text-align: left;
}
td,
th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: 'tnum';
    -moz-font-feature-settings: 'tnum';
    -ms-font-feature-settings: 'tnum';
    -webkit-font-feature-settings: 'tnum';
    padding-left: 0.96667rem;
    padding-right: 0.96667rem;
    padding-top: 0.725rem;
    padding-bottom: calc(0.725rem - 1px);
}
th:first-child,
td:first-child {
    padding-left: 0;
}
th:last-child,
td:last-child {
    padding-right: 0;
}
tt,
code {
    background-color: hsla(0, 0%, 0%, 0.04);
    border-radius: 3px;
    font-family: 'SFMono-Regular', Consolas, 'Roboto Mono', 'Droid Sans Mono',
        'Liberation Mono', Menlo, Courier, monospace;
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}
pre code {
    background: none;
    line-height: 1.42;
}
code:before,
code:after,
tt:before,
tt:after {
    letter-spacing: -0.2em;
    content: ' ';
}
pre code:before,
pre code:after,
pre tt:before,
pre tt:after {
    content: '';
}
main {
    padding-top: 95px;
    height: 100%;
}
.menu {
    ${generateResponsiveCSS('menu')}
}
p {
    margin: 0;
    padding: 0;
    color: ${theme.black};
    font-family: ${theme.fonts.main};
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01em;
    ${generateResponsiveCSS('p', 'general')}
}
h1 {
    margin: 0;
    color: inherit;
    font-family: ${theme.fonts.main};
    font-weight: bold;
    text-rendering: optimizeLegibility;
    ${generateResponsiveCSS('h1', 'general')}
}
h2 {
    margin: 0;
    padding: 0;
    color: ${theme.black};
    font-family: ${theme.fonts.main};
    font-weight: bold;
    text-rendering: optimizeLegibility;
    ${generateResponsiveCSS('h2', 'general')}
}
h3 {
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: ${theme.fonts.main};
    font-weight: bold;
    text-rendering: optimizeLegibility;
    ${generateResponsiveCSS('h3', 'bubbles')}

}
h4 {
    margin: 0 0 10px 0;
    padding: 0;
    font-family: ${theme.fonts.main};
    text-rendering: optimizeLegibility;
    ${generateResponsiveCSS('h4', 'bubbles')}
    & span.bigger {
        ${generateResponsiveCSS('h4', 'bigger')}
        font-weight: normal;
    }

}
h5 {
    margin: 0;
    padding: 0 25px;
    color: ${theme.black};
    font-family: ${theme.fonts.main};
    letter-spacing: 0.01em;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    ${generateResponsiveCSS('h5', 'general')}
}
h6 {
    margin: 0;
    padding: 0 25px;
    color: ${theme.black};
    font-family: ${theme.fonts.main};
    font-weight: normal;
    text-rendering: optimizeLegibility;
    letter-spacing: 0.01em;
    padding-top: 8px;
    ${generateResponsiveCSS('h6', 'general')}
}
.product {
    
    & h2 {
        ${generateResponsiveCSS('h2', 'section')}
    }
    & > *:first-child p {
        ${generateResponsiveCSS('p', 'productDescription')}
    }
    & .dotted-border-mobile {
        @media screen and (min-width: 720px) {
            display: none;
        }
    }
    & .vertical-spacing.dotted-border-mobile + .final-section .vertical-spacing:first-of-type {
    @media screen and (max-width: 719px) {
        margin-top: 0;
        padding-top: 0;
    }
}
}
.product, .team {
    & h1 {
        margin-bottom: 1rem;
        ${generateResponsiveCSS('h1', 'section')}
        & + p {
            ${generateResponsiveCSS('p', 'productDescription')}
        }
    }
    & > *:first-child {
        &.hero-image {
            height: 250px;
            @media screen and (min-width: 575px) {
                height: 350px;
            }
            @media screen and (min-width: 720px) {
                height: 350px;
            }
            @media screen and (min-width: 1024px) {
                height: 400px;
            }
            @media screen and (min-width: 1440px) {
                height: 500px;
            }

        }
    }
    
}
.contact {
    & h1:first-of-type + p {
        ${generateResponsiveCSS('p', 'productDescription')}
    }
}

.bubbles {
    & h2 {
        ${generateResponsiveCSS('h2', 'bubbles')}
    }
    & h3 {
        ${generateResponsiveCSS('h3', 'bubbles')}
    }
    & h4 {
        ${generateResponsiveCSS('h4', 'bubbles')}
    }
    & .bubble-top, & .bubble-bottom {
        & h2 {
            ${generateResponsiveCSS('h2', 'bubblesTopBottom')}
        }
        & p {
            ${generateResponsiveCSS('p', 'bubblesTopBottom')}
        }
    }
}
.carousel {
    & h2 {
        ${generateResponsiveCSS('h2', 'carousel')}
    }
}
.home {
    & h2 {
        ${generateResponsiveCSS('h2', 'home')}
    }
}
&.home-image-container {
        ${generateResponsiveCSS('imageAndText', 'home')}
    }
.grid {
    & h3 {
        ${generateResponsiveCSS('h3', 'grid')}
    }
}
.media {
    & h2 {
        ${generateResponsiveCSS('h2', 'media')}
    }
    & h3 {
        ${generateResponsiveCSS('h3', 'media')}
    }
    & h4 {
        ${generateResponsiveCSS('h4', 'media')}
    }
    & .article-image-container img {
        object-fit: cover;
        width: 100%;
        height: 280px;
        @media screen and (min-width: 575px) {
            height: 400px;
        }
        @media screen and (min-width: 720px) {
            height: 204px;
        }
        @media screen and (min-width: 1024px) {
            height: 260px;
        }
        @media screen and (min-width: 1440px) {
            height: 280px;
        }
    }
}
.manifesto {
    & > *:first-child {
        p {
            font-size: 14px;
            line-height: 28px;
            @media screen and (min-width: 575px) {
                font-size: 20px;
                line-height: 40px;
            }
            @media screen and (min-width: 720px) {
                font-size: 14px;
                line-height: 28px;
            }
            @media screen and (min-width: 1024px) {
                font-size: 20px;
                line-height: 40px;
            }
            @media screen and (min-width: 1440px) {
                font-size: 24px;
                line-height: 48px;
            }
        }
    }
}
// added this because of 315 - bug list
.team {
     & > *:first-child p {
         font-size: 14px;
         line-height: 28px;
         @media screen and (min-width: 575px) {
            font-size: 20px;
            line-height: 40px;
         }
         @media screen and (min-width: 720px) {
             font-size: 14px;
             line-height: 28px;
         }
         
         @media screen and (min-width: 1024px) {
             font-size: 20px;
             line-height: 40px;
         }
         @media screen and (min-width: 1440px) {
             font-size: 24px;
             line-height: 48px;
         }
     }
}

.vertical-spacing {
    margin-top: 32px;
    margin-bottom: 32px;
    @media screen and (min-width: 575px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    @media screen and (min-width: 720px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    @media screen and (min-width: 1024px) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    @media screen and (min-width: 1440px) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    &.vertical-spacing-padding {
        margin-top: initial;
        margin-bottom: initial;
        padding-top: 40px;
        padding-bottom: 40px;
        @media screen and (min-width: 575px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        @media screen and (min-width: 720px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        @media screen and (min-width: 1024px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        @media screen and (min-width: 1440px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
    &.smaller-spacing {
            margin-top: 25px;
            margin-bottom: 25px;
            @media screen and (min-width: 575px) {
                margin-top: 30px;
                margin-bottom: 30px;
            }
            @media screen and (min-width: 720px) {
                margin-top: 30px;
                margin-bottom: 30px;
            }
            @media screen and (min-width: 1024px) {
                margin-top: 50px;
                margin-bottom: 50px;
            }
            @media screen and (min-width: 1440px) {
                margin-top: 70px;
                margin-bottom: 70px;
            }
        }
}
}
.vertical-spacing + .vertical-spacing {
    margin-top: 0;
}
.vertical-spacing:not(.dotted-border-mobile) + .final-section .vertical-spacing:first-of-type {
    margin-top: 0;
    padding-top: 0;
}
.vertical-spacing.dotted-border-mobile + .final-section .vertical-spacing:first-of-type {
    @media screen and (max-width: 1023px) {
        margin-top: 0;
        padding-top: 0;
    }
}
.home + .vertical-spacing {
    @media screen and (max-width: 719px) {
        margin-top: 0;
    }
}
.vertical-spacing + .bubbles > .vertical-spacing:first-child {
    margin-top: 0;
}
.bubbles + .vertical-spacing {
    margin-top: 0;
}
.vertical-spacing + .desktop-only + .vertical-spacing {
    @media screen and (max-width: 719px) {
        margin-top: 0;
    }
}
.goodlife .bubbles h3 {
    @media screen and (min-width: 1440px) {
        font-size: ${theme.responsiveElements.h3.bubbles[1440].fontSize - 4}px;
    }
}
.us-army .bubbles .bubble p:not(:only-child) {
    @media screen and (min-width: 1024px) and (max-width: 1440px) {
        font-size: ${theme.responsiveElements.p.general[1024].fontSize - 4}px;
    }
}
.link-with-svg {
    & a, & span {
        ${generateResponsiveCSS('a', 'linkWithSvg')}
    }
}
.tags {
    ${generateResponsiveCSS('tags', 'general')};
}

.insights.insights {
    flex: 1;
    justify-content: flex-start;
    min-height: calc(100vh - 95px);
    @media screen and (min-width: 720px) {
        min-height: 100vh;
    }
    & .text:first-of-type {
        margin-bottom: 1rem;
    }
}
@media (min-width: 720px) {
    main {
        padding-top: 0;
    }
}

& h2 {
    span.bigger {
        font-weight: bold;
        text-align: left;
        display: block;
        ${generateResponsiveCSS('h2', 'bigger')}
    }
}
& p {
    span.bigger {
        text-align: left;
        display: block;
        ${generateResponsiveCSS('p', 'bigger')};
    }
}

.no-bottom-space, .no-bottom-space h2, .no-bottom-space p.block-img {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}
.no-bottom-space-mobile {
    @media screen and (max-width: 719px) {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
}
.no-top-space {
    margin-top: 0 !important;
    padding-top: 0 !important;
}
.icon {
    font-family: 'Icons', "Söhne Breit";
    text-decoration: none !important;
}
/* Fonts */
@font-face {
    font-family: "Söhne Breit";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url(${Buch});
}

@font-face {
    font-family: "Söhne Breit";
    font-style: normal;
    font-weight: bold;
    font-display: block;
    src: url(${Dreiviertelfett});
}

@font-face {
    font-family: "Söhne Breit";
    font-style: italic;
    font-weight: normal;
    font-display: block;
    src: url(${BuchKursiv});
}

@font-face {
    font-family: "Icons";
    src: url(${Icons});
}
`;

export default GlobalStyle;
